import '../index.css';

function Top() {
    return (
        <div className="messageTop">      
            <p>Réduction sur toute la commande 20%</p>
        </div>
    );
  }
  
  export default Top;
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './mobile.css';
import Top from './components/Top';
import Bar from './components/Bar';
import TopProduct from './components/TopProduct';
import Categorie from './components/Categorie';
import ListProducts from './components/ListeProducts';
import Meilleurvente from './components/Meilleurvente';
import CovertureImg from './components/CovertureImg';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import ProductDetails from './components/ProductDetails';
import { CartProvider } from "./components/context";
import Footer from './components/Footer';
import SideBar from './components/SideBar';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Top />
    <Bar />
    <SideBar />
    <div className='app-container'>
      <BrowserRouter>
      <ScrollToTop />
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/Product" element={<ProductDetails />} />
          </Routes>
      </BrowserRouter>
    </div>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import ImageCat1 from "../images/ImageCat1.png";
import ImgEtudiant from "../images/etudiant.png";
import ImgVoyage from "../images/Voyage.png";
import ImgBusiness from "../images/Business.png";
import ImgFemmes from "../images/Femmes.png";

export const categories = [
    {
        label: 'Tout la collection',
        img: ImageCat1,
    },
    {
        label: 'Etudiant',
        img: ImgEtudiant,
        background: 'antiquewhite',
    },
    {
        label: 'Femmes',
        img: ImgFemmes,
        background: 'aliceblue',
    },
    {
        label: 'Business',
        img: ImgBusiness,
        background: 'cadetblue',
    },
    {
        label: 'Voyage',
        img: ImgVoyage,
        background: 'beige',
    }
]
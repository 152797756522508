import { Container, Carousel } from "react-bootstrap";
import "../index.css";
import ImageCat1 from "../images/ImageCat1.png";
import ImgEtudiant from "../images/etudiant.png";
import ImgVoyage from "../images/Voyage.png";
import ImgBusiness from "../images/Business.png";
import ImgFemmes from "../images/Femmes.png";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { categories as cats } from '../assets/constants'
function Categorie() {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const categories = () => {
    return (
      <>
        <div className="Categorie parent">
          {cats.map((e, i) => (
            <a href={i === 0 ? "/" : "/?category=" + e.label} class={'div' + (i + 1) + ' px-0'} title="cat1">
              <img className={i == 0 ? 'grandImg' : 'smallImg'} src={e.img} />
              <div className="catInfo">
                <h1 style={{ width: '103px' }}>{e.label}</h1>
              </div>
            </a>
          ))}
        </div>
      </>
    )
    // return (
    //   <>
    //     <div className="Categorie parent">
    //       {categories}
    //       <a href="#cat1" class="div1" title="cat1">
    //         <img className="grandImg" src={ImageCat1} />
    //         <div className="catInfo">
    //           <h1>Tout la collection</h1>
    //           <p>3232 Produits</p>
    //         </div>
    //       </a>

    //       <a href="#cat2" class="div2" title="cat2">
    //         <img className="smallImg" src={ImgEtudiant} />
    //         <div className="catInfo">
    //           <h1>Etudiant</h1>
    //           <p>232 Produits</p>
    //         </div>
    //       </a>
    //       <a href="#cat3" class="div3" title="cat3">
    //         <img className="smallImg" src={ImgFemmes} />
    //         <div className="catInfo">
    //           <h1>Femmes</h1>
    //           <p>323 Produits</p>
    //         </div>
    //       </a>
    //       <a href="#cat4" class="div4" title="cat4">
    //         <img className="smallImg" src={ImgBusiness} />
    //         <div className="catInfo">
    //           <h1>Business</h1>
    //           <p>232 Produits</p>
    //         </div>
    //       </a>
    //       <a href="#cat5" class="div5" title="cat5">
    //         <img className="smallImg" src={ImgVoyage} />
    //         <div className="catInfo">
    //           <h1>Voyage</h1>
    //           <p>323 Produits</p>
    //         </div>{" "}
    //       </a>
    //     </div>
    //   </>
    // )
  }
  const settings = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: false,
          infinite: false,
          slidesToShow: 1,
          vertical: false,
          centerMode: true,
          centerPadding: '23%',
        }
      }
    ]
  };

  return (
    !isMobile ? <Container className="pb-3">
      {categories()}
    </Container>

      : <Container className="categories">
        <Slider {...settings}>
          {cats.map((e, i) => (
            <a href={i === 0 ? "/" : "/?category=" + e.label} >
              <div className="position-relative"  >
                <img src={e.img} alt="Credit to Alisa Anton on Unsplash" style={{ backgroundColor: e.background || 'none' }} />
                <div className="position-absolute desc">
                  <h6>{e.label}</h6>
                </div>
              </div>
            </a>
          ))}
        </Slider>
      </Container>
  );
}

export default Categorie;

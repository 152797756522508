import React, { useState } from "react";
import { Navbar, Nav, Container, Badge, Modal, Row, Col, Form, Button } from 'react-bootstrap';
import { FaShoppingCart, FaHeart, FaBars } from 'react-icons/fa';
import logo from '../logo.png';
import '../index.css';
import Cart from './Cart';
import { post } from "../api/ApiCall";
import { getItemsCart, setItemsCart } from "./context";
import Swal from "sweetalert2";
import { on, trigger } from "./event";
import { Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Slider, styled } from "@mui/material";
import './style/styles.scss';
import { useNavigate } from "react-router";

function Bar() {

  const [showCart, setShowCart] = useState(false);
  const [showCartNotif, setShowCartNotif] = useState(getItemsCart().length > 0);
  const [showCommadPage, setShowCommadPage] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [priceRange, setPriceRange] = React.useState([getQueryString('prixMin') || 0, getQueryString('prixMax') || 3000]);
  const [filter, setFilter] = React.useState({
    category: getQueryString('category') || null,
    prixMin: getQueryString('prixMin') || priceRange[0],
    prixMax: getQueryString('prixMax') || priceRange[1]
  });

  const handleChange = (event, newValue) => {
    setPriceRange(newValue);
    const f = { ...filter };
    f.prixMin = newValue[0];
    f.prixMax = newValue[1];
    setFilter(f);
  };
  const [clientInfo, setClientInfo] = useState({
    fullName: '',
    address: '',
    city: '',
    phoneNumber: '',
    email: '',
    postalCode: ''
  });


  on('cartChange', () => {
    setShowCartNotif(getItemsCart().length > 0);
  })


  const handleCartModalClose = () => {
    setShowCart(false);
  }
  const handleCommadPageModalClose = () => {
    setShowCommadPage(false);
  }

  const handleFilterClose = () => {
    setShowFilter(false);
  }

  const setFilterChangre = (key, value) => {
    const f = { ...filter };
    let fieldName = key;
    let fleldVal = value;
    f[fieldName] = fleldVal;
    setFilter(f);
    console.log(f);
  }
  const setChange = (e) => {
    const ci = { ...clientInfo };
    let fieldName = e.target.name;
    let fleldVal = e.target.value;
    ci[fieldName] = fleldVal;
    setClientInfo(ci);
  }

  const PrettoSlider = styled(Slider)({
    color: '#ebaa34',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      backgroundColor: '#ebaa34',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#ebaa34',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  return (
    <>
      <Navbar>
        <Container>
          <Navbar.Brand href="/">
            <img className='logoImg' src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link onClick={() => { setShowCart(true) }}><FaShoppingCart style={{ fontSize: '20px' }} /> {showCartNotif && <Badge bg="danger" >.</Badge>}</Nav.Link>
              {/* <Nav.Link href="#deets"><FaHeart style={{ fontSize: '20px' }} /></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='p-3'>
        <FaBars onClick={() => { setShowFilter(true) }} style={{ fontSize: '30px', color: '#EBAA33' }} />
      </Container>
      {showCart && <Modal show={showCart} size='xl' style={{ borderRadius: '0px' }} onHide={handleCartModalClose}>
        <Modal.Header style={{ border: '0px' }} closeButton>
          <Modal.Title style={{ margin: '41px 0px 0px 54px', color: 'GrayText' }}>Panier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cart onClick={() => { setShowCommadPage(true); handleCartModalClose() }} />
          <div className="back-to-shop">
            <a href="#">←</a>
            <span className="text-muted">Back to shop</span>
          </div>
        </Modal.Body>
      </Modal>}

      {showCommadPage && <Modal show={showCommadPage} style={{ borderRadius: '0px' }} size='xl' onHide={handleCommadPageModalClose}>
        <Modal.Header style={{ border: '0px' }} closeButton>
          <Modal.Title style={{ margin: '41px 0px 0px 54px', color: 'GrayText' }}>Les informations personel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(
            <Container>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Nom et prenom</Form.Label>
                    <Form.Control type='text' onChange={setChange} value={clientInfo.fullName} defaultValue={clientInfo.fullName} name='fullName' placeholder="Nom et prenom" />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control onChange={setChange} value={clientInfo.address} defaultValue={clientInfo.address} name='address' type='text' placeholder="Adress" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Ville</Form.Label>
                    <Form.Control type='text' onChange={setChange} value={clientInfo.city} defaultValue={clientInfo.city} name="city" placeholder="Ville" />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Tel.</Form.Label>
                    <Form.Control type='text' onChange={setChange} value={clientInfo.phoneNumber} defaultValue={clientInfo.phoneNumber} name="phoneNumber" placeholder="Tel" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" onChange={setChange} value={clientInfo.email} defaultValue={clientInfo.email} placeholder="Email" />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Code postal.</Form.Label>
                    <Form.Control type="number" name="postalCode" onChange={setChange} value={clientInfo.postalCode} defaultValue={clientInfo.postalCode} placeholder="Code postal" />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer style={{ border: '0px' }}>
          <div className="divshopnow d-inline m-auto py-5 container">
            <div className="row">
              <div className="col text-center">
                <button className="shopnow" onClick={() => {
                  let items = getItemsCart(),
                    postItems = items.map((item) => ({
                      'ReffCmd': 'CMD-' + item.id + '-' + clientInfo.email,
                      'ReffClient': clientInfo.email,
                      'ReffProd': item.Ref,
                      'QntProd': item.quantity,
                      'colorProd': item.color,
                      'villeCmd': clientInfo.city,
                      'AdresseCMD': clientInfo.address,
                      'dateCmd': Date.now,
                      'statusCmd': false,
                      'PaimentCmd': parseFloat(item.prix) * parseFloat(item.quantity),
                      'prixCmd': item.prix,
                    }));

                  let promises = [];
                  postItems.forEach(element => {
                    promises.push(post('commands', '', { command: element, client: clientInfo }))
                  });
                  Promise.all(promises).then((values) => {
                    setItemsCart([]);
                    setShowCommadPage(false);
                    new Swal({
                      title: "Merci!",
                      text: "Votre commande est envoyé avec succés!",
                      icon: "success",
                      button: "Continue!",
                    });
                  });
                }} name="shope">Validé</button>
              </div>
              <div className="col text-center">
                <button className="cancel" onClick={handleCommadPageModalClose} variant="outline-secondary"   >Annuler</button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>}

      {showFilter && <Modal contentClassName="filter-container" show={showFilter} size='xl' style={{ borderRadius: '0px' }} onHide={handleFilterClose}>
        <Modal.Body>

          <Container  >
            <Row>
              <Modal.Header style={{ border: '0px' }} closeButton>
                <Modal.Title style={{ margin: '41px 0px 0px 0px', color: 'GrayText' }}>Produits Categorie</Modal.Title>
              </Modal.Header>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={getQueryString('category')}
                  name="radio-buttons-group"
                  onChange={(e) => {
                    setFilterChangre('category', e.target.value)
                  }}
                >

                  {['toute la collection', 'Etudiant', 'Business', 'Voyage', 'Femmes', 'Hommes', 'Wallets'].map((l, i) => (
                    <FormControlLabel value={l === 'toute la collection' ? null : l} control={<Radio />} label={l} />

                  ))}
                </RadioGroup>
              </FormControl>
            </Row>
            <Divider className="my-5" sx={{ borderBottomWidth: 3 }} style={{ backgroundColor: 'grey' }} variant="middle" />
            <Row >
              <Modal.Header style={{ border: '0px' }} >
                <Modal.Title style={{ margin: '30px 0px 0px 0px', color: 'GrayText' }}>Filtre par prix</Modal.Title>
              </Modal.Header>
              <PrettoSlider
                value={priceRange}
                max={3000}
                min={0}
                onChange={handleChange}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
                size="small"
              />
              <div className="my-2">
                Prix {priceRange[0]} - {priceRange[1]}
              </div>
              <div className="py-5"></div>
            </Row>
            <div className="my-4 divshopnow text-center">
              <button className="shopnow text-nowrap" onClick={() => {
                trigger('filterChanged', filter);
                window.history.pushState(filter, '/', '?' + createQueryString(filter));
                handleFilterClose();
              }} >Rechercher</button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>}
    </>
  );
}

function valuetext(value) {
  return `${value}DH`;
}
export const createQueryString = (objParam) => {
  const queryString = Object.keys(objParam)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(objParam[k])}`)
    .join("&");
  return queryString;
};

export const getQueryString = (key) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let value = params[key]; // "some_value"
  return value;
}
export default Bar;
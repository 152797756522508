import React, { useCallback, useContext, useEffect, useState } from "react";
import { FILE_BASE_URL } from "../api/ApiCall";
import { calculateReduction, getItemsCart, removeItemCart, setItemsCart } from "./context";

const Cart = ({ onClick }) => {
    const items = getItemsCart();

    const [cartTotal, setCartTotal] = useState(0);
    const [itemsCopy, setItemsCopy] = useState([]);
    const [isInitiallyFetched, setIsInitiallyFetched] = useState(false);

    const handleClick = (e, item) => {
        e.preventDefault();
    };

    const total = useCallback(() => {
        setCartTotal(itemsCopy.reduce((acc, item) => parseInt(acc, 10) + (item.quantity * calculateReduction(parseInt(item.prix, 10), item.remis)), 0));
    }, [itemsCopy]);


    useEffect(() => {
        if (!isInitiallyFetched) {
            setItemsCopy(items);
            setIsInitiallyFetched(true);
        }
        total();
    }, [itemsCopy, cartTotal, isInitiallyFetched, total, items])

    return (
        <div className="row">
            <div className="col-md-8 cart">
                {itemsCopy.map((item, i, c) => (<div className="row border-top border-bottom">
                    <div className="row main align-items-center">
                        <div className="col-2">
                            <img className="img-fluid" src={FILE_BASE_URL + item.img1} />
                        </div>
                        <div className="col">
                            <div className="row text-muted">{item.titre}</div>
                            <div className="row">{item.Ref}</div>
                        </div>
                        <div className="col">
                            <a href="#" onClick={() => {
                                const ic = itemsCopy.map(l => Object.assign({}, l));
                                ic[i].quantity = parseInt(ic[i].quantity, 10) - 1;
                                setItemsCopy(ic);
                                setItemsCart(ic);
                            }}>-</a>
                            <a href="#" className="border">
                                {item.quantity}
                            </a>
                            <a href="#" onClick={() => {
                                const ic = itemsCopy.map(l => Object.assign({}, l));
                                ic[i].quantity = parseInt(ic[i].quantity, 10) + 1;
                                setItemsCopy(ic);
                                setItemsCart(ic);
                            }}>+</a>
                        </div>
                        <div className="col" onClick={e => {
                            let items = itemsCopy;
                            const newItems = items.filter(
                                _item => _item.id !== item.id
                            );
                            setItemsCopy(newItems);
                            removeItemCart(item.id,item.color);
                            total();
                        }} >
                            DH {calculateReduction(item.prix, item.remis)} <span className="close">✕</span>
                        </div>
                    </div>
                </div>))}
            </div>
            <div className="col-md-4 summary">
                <div>
                    <h5>
                        <b>Sommaire</b>
                    </h5>
                </div>
                <hr />
                <div className="row">
                    {
                        itemsCopy.map(v => (
                            <>
                                <div className="col" style={{ paddingLeft: 0 }}>
                                    {v.titre}
                                </div>
                                <div className="col text-right">DH {calculateReduction(v.prix, v.remis)}</div>
                            </>
                        ))
                    }

                </div>
                <form>
                    <p>Livraison</p>
                    <select style={{ width: '100%' }}>
                        <option className="text-muted">Livraison standard- Gratuit</option>
                    </select>
                    <p>Coupon code</p>
                    <input id="code" placeholder="Enter your code" />
                </form>
                <div
                    className="row"
                    style={{ borderTop: "1px solid rgba(0,0,0,.1)", padding: "2vh 0" }}
                >
                    <div className="col">TOTAL PRICE</div>
                    <div className="col text-right">DH {cartTotal}</div>
                </div>
                <div className="my-4 divshopnow">
                    <button className="shopnow text-nowrap" onClick={onClick} >Acheter</button>
                </div>
            </div>
        </div>

    );
};

export default Cart;



import '../index.css';

import { Container } from "react-bootstrap";
import { FaFacebook, FaInstagram,FaTwitter,FaTiktok } from 'react-icons/fa';


function SideBar() {
    return (
        <Container className='side-bar postion-relative'>
            <div className='position-fixed' style={{ width: '35px', left: '2%' }}>
                <div class="widget no-box" >
                    <h6 class="widget-title mx-auto" style={{
                        writingMode: 'vertical-rl',
                        textOrientation: 'mixed'
                    }}>Follow us on<span></span></h6>

                    <ul class="social-footer2">
                        <li class=""><a title="tiktok" target="_blank" href="https://www.tiktok.com/">  <FaTiktok style={{ fontSize: '20px', color: '#000' }} /></a></li>
                        <li class=""><a href="https://www.facebook.com/" target="_blank" title="Facebook"> <FaFacebook style={{ fontSize: '20px', color: '#000' }} /></a></li>
                        <li class=""><a href="https://twitter.com" target="_blank" title="Twitter"><FaTwitter style={{ fontSize: '20px', color: '#000' }} /></a></li>
                        <li class=""><a title="instagram" target="_blank" href="https://www.instagram.com/"><FaInstagram style={{ fontSize: '20px', color: '#000' }} /></a></li>
                    </ul>
                </div>
            </div >
        </Container>


    );
}

export default SideBar;
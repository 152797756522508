import { Container, Col, Row } from "react-bootstrap";
import "../index.css";
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { useContext, useEffect, useRef, useState } from "react";
import { BASE_URL, get } from "../api/ApiCall";
import Gallery from "./Gallery";
import './style/styles.scss';
import { useLocation } from 'react-router-dom';
import { AddCartContext, addItemCart, calculateReduction } from "./context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBuildingShield, faTruck } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import Meilleurvente from "./Meilleurvente";
import Swal from "sweetalert2";

library.add(faTruck, faBuildingShield);

function ProductDetails(props) {
    const [product, setProduct] = useState({});
    const [color, setColor] = useState(0);
    const addItems = useContext(AddCartContext);
    const imageRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        get('Products', searchParams.get("p")).then((r) => {
            r.images = [r.img1, r.img2, r.img3, r.img4, r.img5]
            r.images = r.images.filter(x => !x.endsWith('.'))
            r.quantity = 1;
            setProduct(r);
        });
    }, [searchParams]);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;


    const handleClick = (e, item) => {
        e.preventDefault();
        item.color = product['couleur' + color];
        addItemCart(item);
        new Swal({
            title: "Merci!",
            text: "Votre Produit est Ajouter dans le panier avec succés!",
            icon: "success",
            button: "Continue!",
          });
    };
    return (
        <>
            <div id="orig-product">
                <Container >
                    <Row>
                        {!isMobile && <Col md={5}>
                            {product.images &&
                                <Gallery ref={imageRef} images={product.images} />
                            }
                        </Col>
                        }
                        <Col md={7}>
                            <div className="productInfo col" id="details">
                                <div>
                                    <input type="number" name="p1" defaultValue={1} required style={{ display: 'none' }} />
                                    <h1 id="titre" style={{ fontWeight: 'bold' }}>{product.titre}</h1>
                                    <div className="description">
                                        {product.description}
                                    </div>
                                    <div className="px-2">
                                        <p className="mt-3 reference">
                                            Réfe : {product.Ref}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isMobile && <Col  md={5}>
                                    {product.images &&
                                        <Gallery ref={imageRef} images={product.images} />
                                    }
                                </Col>
                                }                        </div>
                            <div className="productInfo col" id="details" >
                                <div className="row my-1">
                                    <div className="col-6 col-md-4">
                                        <p>Coleur :</p>
                                        {
                                            [1, 2, 3, 4, 5].map((v) => (
                                                product['couleur' + v] !== '#f2f2f2' && <>
                                                    <input type="radio" name={'color' + v} id={'color' + v} checked={color === v} value={product['couleur' + v]} onChange={(e) => {
                                                        const p = product;
                                                        p.color = e.target.value
                                                        setProduct(p);
                                                        console.log(e);
                                                    }} />
                                                    <label className="color-picker"><span style={{ backgroundColor: product['couleur' + v] }} onClick={() => { setColor(v); imageRef.current.img.src = BASE_URL + '/file/' + product['Imgcouleur' + v] }}></span></label>
                                                </>
                                            ))
                                        }
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <p>Quantié :</p>
                                        <select className="quantity" defaultValue={product.quantity} onChange={(e) => {
                                            const p = { ...product };
                                            p.quantity = e.target.value;
                                            setProduct(p);
                                            console.log(p);
                                        }}>
                                            {[1, 2, 3, 4, 5].map((v) => (
                                                <option value={v}  >{v}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <p>Prix :</p>
                                        {
                                            product.remis ? <h5 style={{ color: 'red' }} className="prix text-nowrap"><s style={{ color: 'black' }}>{product.prix * product.quantity}</s> <s style={{ color: 'black' }}>DH</s> &nbsp;
                                                {calculateReduction(product.prix, product.remis) * product.quantity}
                                                DH</h5> : <h5 className="prix text-nowrap">
                                                {calculateReduction(product.prix, product.remis) * product.quantity}
                                                DH</h5>
                                        }
                                    </div>
                                </div>
                                <div >
                                    <div className="my-4 divshopnow">
                                        <button className="shopnow text-nowrap" onClick={e => handleClick(e, product)} name="shope">SHOP NOW</button>
                                    </div>
                                </div>
                                <div className="p-3" style={{ backgroundColor: '#f5f7fb' }}>
                                    <FontAwesomeIcon icon={["fa", "truck"]} />
                                    <span name style={{ fontWeight: 'bold', marginLeft: '3px' }}>Livraison</span>
                                    <p className="ml-5" style={{ fontSize: '12px' }}>Les frais de livraison sont calculés une fois la
                                        commande finalisée. Délai de réception = Délai de processus + Délai de livraison</p>
                                    <FontAwesomeIcon icon={["fa", "building-shield"]} /><span style={{ fontWeight: 'bold', marginLeft: '3px' }}>Politque de retour</span>
                                    <p className="ml-5" style={{ fontSize: '12px' }}>Notre Garantie Retour Ou Echange Dans 30 Jours A
                                        Partir De La Date De Livraison.</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div >
            <Meilleurvente />
        </>


    );
}
export default ProductDetails;

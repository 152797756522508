import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './mobile.css';
import Top from './components/Top';
import Bar from './components/Bar';
import TopProduct from './components/TopProduct';
import Categorie from './components/Categorie';
import ListProducts from './components/ListeProducts';
import Meilleurvente from './components/Meilleurvente';
import CovertureImg from './components/CovertureImg';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

export const App = () =>
(
    <React.StrictMode>
        <TopProduct />
        <Categorie />
        <ListProducts />
        <Meilleurvente />
        <CovertureImg />
    </React.StrictMode>
);


import { fontWeight } from '@mui/system';
import { Container, Row, Col } from 'react-bootstrap';
import '../index.css';

function CovertureImg() {
    return (
        <Container fluid className='CovertureImg'>
            <Row className='text-center'>
                <Col md={6} className='pt-5'>
                    <div>
                        <h1 style={{ fontFamily: 'Adine', fontSize: '6em', fontWeight: 'bolder' }}>
                            Guide des sacs
                        </h1>
                        <h3>Pour Homme 2022</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit, sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut
                            aliquip ex ea commodo consequat.</p>
                    </div>
                </Col>
                <Col md={4}>
                </Col>
            </Row>
        </Container>
    );
}

export default CovertureImg;
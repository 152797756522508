import { Container, Col, Row } from "react-bootstrap";
import "../index.css";
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { useState } from "react";
import { Link } from "react-router-dom";
import { FILE_BASE_URL } from "../api/ApiCall";
// import Image4 from '../images/Image 4.png';
let testNew, txtspan;


function Product(props) {
  testNew = props.new;
  if (testNew == 'new') {
    document.getElementsByClassName('divnew').display = 'block';
  } else {
    txtspan = '';
  }

  return (
    <Col xs={6} sm={4} md={4}>
      <a onClick={props.onClick} className='text-decoration-none' href={'/Product?p='+props.id}>
      <div className="product" >
        <div className="product-img">
          <img className="img-fluid" src={FILE_BASE_URL + props.image} />
        </div>
        <div className="product-info">
          <h1 className="titreProduct">{props.name}</h1>
          <Row>
            <Col md={4}>
              <h1 className="prix">{props.prix} dh</h1>
            </Col>
            <Col md={4}>
            {props.color1 !==  '#f2f2f2'&&  <span
                class="dot"
                style={{ backgroundColor: props.color1 }}
              ></span>}
              {props.color2 !==  '#f2f2f2'&& <span
                class="dot"
                style={{ backgroundColor: props.color2 }}
              ></span>}
             {props.color3 !==  '#f2f2f2'&& <span
                class="dot"
                style={{ backgroundColor: props.color3 }}
              ></span>}
            </Col>
          </Row>
        </div>
        {txtspan}
        <span className="divnew" >NEW</span>
        <span className="divprix" >-10%</span>
      </div>
      </a>
    </Col>
  );
}
export default Product;

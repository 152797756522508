import "../index.css";
import "../mobile.css";
import { Row, Col, Container, Badge, Carousel } from "react-bootstrap";
import { } from "react-icons/fa";
import Image1 from "../images/Image 11.png";
import SlideProduct from "./SlideProduct";
import { useEffect, useState } from "react";
import { post } from "../api/ApiCall";

function TopProduct() {

    const [products, setProducts] = useState([]);
    const [paging, setPaging] = useState({});


    useEffect(() => {
        post('Products', 'search', {
            displayCarousel: 1, paging: {
                page: 1
            }
        }).then(r => {
            setProducts(r.data);
            setPaging(r.meta);
        })
    }, [])
    return (
        <>
            <Container className="TopProduct" style={{backgroundColor : 'white'}}>
                <Carousel interval={null} >
                    {
                        products.map((e, i) => (
                            <Carousel.Item>
                                <SlideProduct
                                    id={e.id}
                                    titre={e.titre}
                                    description={e.description}
                                    prix={e.prix}
                                    color1={e.couleur1}
                                    color2={e.couleur2}
                                    color3={e.couleur3}
                                    image={e.img1}
                                />
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
            </Container>
        </>
    );
}

export default TopProduct;

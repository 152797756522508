//
import { Container, Col, Row, Card } from "react-bootstrap";
import "../index.css";

import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components


// import "./styles.css";

import Image4 from '../images/Image 4.png';

import ImageCat1 from "../images/ImageCat1.png";
import ImgEtudiant from "../images/etudiant.png";
import ImgVoyage from "../images/Voyage.png";
import ImgBusiness from "../images/Business.png";
import ImgFemmes from "../images/Femmes.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { Link } from "react-router-dom";
import { FILE_BASE_URL, get, post } from "../api/ApiCall";
import { calculateReduction } from "./context";

function Meilleurvente() {


  const [products, setProducts] = useState([]);
  const [paging, setPaging] = useState({});


  useEffect(() => {
    post('Products', 'search', {
      bestSellings: 'MV', paging: {
        page: 1
      }
    }).then(r => {
      setProducts(r.data);
      setPaging(r.meta);
    })
  }, [])

  const settings = {
    dots: false,
    autoplay: true,
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    autoPlaySpeed: 100,
    slidesToScroll: 1,
    pauseOnHover:true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '9%',
        }
      }
    ]
  };
  return (
    <Container className="Meilleurvente">
      <h1 className="text-center fancy-undeline">Meilleur vente</h1>

      <>
        <Container style={{backgroundColor : 'white'}}>
          <Slider  {...settings}>
            {products.map(p => (
              <Col md={3} className='mt-5'>
                <Link to={'/Product?p=' + p.id}>
                  <Card >
                    <Card.Img variant="top" className='best-selling' src={FILE_BASE_URL + p.img1} />
                    <Card.Body>
                      <Card.Title>{p.titre}</Card.Title>
                      <Card.Text>
                        <Row>
                        <Col className="px-0" >
                          {
                            p.remis ? <h6 style={{ color: 'red' }} className="prix text-nowrap"><s style={{ color: 'black' }}>{p.prix}</s> <s style={{ color: 'black' }}>DH</s> &nbsp;
                              {calculateReduction(p.prix, p.remis)}
                              DH</h6> : <h6 className="prix text-nowrap">
                              {calculateReduction(p.prix, p.remis)}
                              DH</h6>
                          }
                        </Col>
                        <Col >
                          {p.couleur1 !== '#f2f2f2' && <span
                            class="dot"
                            style={{ backgroundColor: p.couleur1 }}
                          ></span>}
                          {p.couleur2 !== '#f2f2f2' && <span
                            class="dot"
                            style={{ backgroundColor: p.couleur2 }}
                          ></span>}
                          {p.couleur3 !== '#f2f2f2' && <span
                            class="dot"
                            style={{ backgroundColor: p.couleur3 }}
                          ></span>}
                        </Col>
                        </Row>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))}
          </Slider>
        </Container>
      </>
    </Container>
  );
}

export default Meilleurvente;
